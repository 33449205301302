import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { loaderActions } from '../state/actions';

// Modal styles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#1B1B1B',
    border: '1px solid #444',
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '500px',
    width: '90%',
    maxHeight: '85vh',
    overflow: 'auto',
    position: 'absolute',
    zIndex: 1000,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const GenericWorkFlow = ({
  workType, // 'maintenance', 'repairing', or 'warranty'
  navigate,
  workStatusData,
  fetchExistingRecords, // Function to fetch existing records
  isEnabled, // If the button should be enabled
  buttonLabel, // Label for the button
  workTypeLabel, // Label for the work type in modals ("Maintenance", "Repair", "Warranty")
  apiEndpoint, // API endpoint for this work type
  additionalFields = [], // Array of additional fields specific to this work type
  onSuccess = null, // Optional callback on successful submission
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showWorkModal, setShowWorkModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false); // New modal for linking to existing work
  const [isLoading, setIsLoading] = useState(false);
  const [vinCode, setVinCode] = useState('');
  const [userId, setUserId] = useState(null);

  // Initialize work data with common fields
  const [workData, setWorkData] = useState({
    work_orderer: '',
    work_orderer_email: '',
    work_orderer_phone_no: '',
    estimated_mileage: '',
    work_order_description: '',
  });

  // Add additional fields based on work type
  useEffect(() => {
    const additionalData = {};
    additionalFields.forEach((field) => {
      additionalData[field.name] = '';
    });
    setWorkData((prevData) => ({ ...prevData, ...additionalData }));
  }, [additionalFields]);

  // Get VIN code and user ID from localStorage on component mount
  useEffect(() => {
    try {
      // Get VIN code
      const vinnData = localStorage.getItem('vinnData');
      if (vinnData) {
        const parsedData = JSON.parse(vinnData);
        const dbData = parsedData?.data;
        setVinCode(dbData?.vin_code || '');
      }

      // Get user ID
      const profileId = localStorage.getItem('profile_id');
      if (profileId) {
        setUserId(+profileId);
      }
    } catch (error) {
      console.error('Error loading data from localStorage:', error);
    }
  }, []);

  // Check if we should show the link to existing work modal
  const shouldShowLinkModal = () => {
    // Only show if we're dealing with repairs or warranty, there's a work_id, and this work type is not enabled
    return (
      workStatusData?.work_id && // There's an existing work_id
      !isEnabled && // Current work type is not enabled
      (workType === 'repairing' || workType === 'warranty') // We're dealing with repair or warranty
    );
  };

  const handleButtonClick = () => {
    if (isEnabled) {
      // If this work type is already enabled, fetch existing records
      fetchExistingRecords();
    } else if (shouldShowLinkModal()) {
      // If we should show the link modal, show it
      setShowLinkModal(true);
    } else {
      // Otherwise, show the standard confirmation modal
      setShowConfirmModal(true);
    }
  };

  const handleConfirmResponse = (response) => {
    setShowConfirmModal(false);
    if (response) {
      setShowWorkModal(true);
    }
  };

  // Handle link to existing work response
  const handleLinkResponse = async () => {
    setShowLinkModal(false);

    try {
      setIsLoading(true);
      dispatch(loaderActions.showLoader());

      const payload = {
        vin_code: vinCode,
        user_id: userId,
        link_to_existing: true,
        work_id: workStatusData.work_id,
      };

      // Make API call to link to existing work
      const response = await axios.post(apiEndpoint, payload);

      console.log('API link response:', response.data);

      // Show success message
      toast.success(`${workTypeLabel} work linked successfully`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });

      // Call onSuccess callback if provided
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(response.data);
      }
    } catch (error) {
      console.error('API error:', error);
      toast.error(
        t(
          `Failed to link ${workTypeLabel.toLowerCase()} work. Please try again.`
        ),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
    } finally {
      setIsLoading(false);
      dispatch(loaderActions.hideLoader());
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorkData({
      ...workData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleAddWork = async () => {
    // Validate common required fields
    if (!workData.work_orderer) {
      alert(t('Please enter work orderer name'));
      return;
    }

    // Check if at least one contact method is provided
    if (!workData.work_orderer_email && !workData.work_orderer_phone_no) {
      alert(t('Please provide either email or phone number'));
      return;
    }

    if (!workData.estimated_mileage) {
      alert(t('Please enter current mileage'));
      return;
    }

    // Validate additional required fields
    for (const field of additionalFields) {
      if (field.required && !workData[field.name]) {
        alert(t(`Please enter ${field.label}`));
        return;
      }
    }

    // Check if VIN code and user ID are available
    if (!vinCode) {
      alert(
        t('VIN code not found. Please refresh the page or contact support.')
      );
      return;
    }

    if (!userId) {
      alert(t('User ID not found. Please log in again.'));
      return;
    }

    // Prepare payload for API
    const payload = {
      vin_code: vinCode,
      user_id: userId,
      ...workData,
    };

    try {
      setIsLoading(true);
      dispatch(loaderActions.showLoader());

      // Make API call
      const response = await axios.post(apiEndpoint, payload);

      console.log('API response:', response.data);

      // Reset form fields
      const resetData = {
        work_orderer: '',
        work_orderer_email: '',
        work_orderer_phone_no: '',
        estimated_mileage: '',
        work_order_description: '',
      };

      // Reset additional fields
      additionalFields.forEach((field) => {
        resetData[field.name] = '';
      });

      setWorkData(resetData);
      setShowWorkModal(false);

      // Call onSuccess callback if provided
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(response.data);
      }

      // Show success message
      toast.success(`${workTypeLabel} work created successfully`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } catch (error) {
      console.error('API error:', error);
      toast.error(
        t(
          `Failed to add ${workTypeLabel.toLowerCase()} work. Please try again.`
        ),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
    } finally {
      setIsLoading(false);
      dispatch(loaderActions.hideLoader());
    }
  };

  return (
    <>
      {/* Main Action Button */}
      <button
        className={`w-full h-12 xl:h-[73px] rounded-[10px] border text-f_14_l_18 xl:text-f_22_l_28 font-supremeMedium uppercase ${
          isEnabled ? 'text-[#FFD009] border-[#FFD009]' : 'border-white'
        }`}
        onClick={handleButtonClick}
      >
        {buttonLabel}
      </button>

      {/* Link to Existing Work Modal */}
      <Modal
        isOpen={showLinkModal}
        onRequestClose={() => setShowLinkModal(false)}
        style={customStyles}
        contentLabel={`Link ${workTypeLabel} to Existing Work`}
        ariaHideApp={false}
      >
        <div className='text-white'>
          <h2 className='text-xl font-bold mb-4'>
            {t(`Link to Existing Work`)}
          </h2>
          <p className='mb-6'>
            {t(
              `There is an existing work order. This ${workTypeLabel.toLowerCase()} will be linked to the existing work order.`
            )}
          </p>
          <div className='flex justify-end gap-4'>
            <button
              className='px-4 py-2 border border-gray-500 rounded hover:bg-gray-700 transition'
              onClick={() => setShowLinkModal(false)}
            >
              {t('Cancel')}
            </button>
            <button
              className='px-4 py-2 bg-gradient rounded font-medium hover:opacity-90 transition'
              onClick={handleLinkResponse}
            >
              {t('Link')}
            </button>
          </div>
        </div>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(false)}
        style={customStyles}
        contentLabel={`${workTypeLabel} Confirmation`}
        ariaHideApp={false}
      >
        <div className='text-white'>
          <h2 className='text-xl font-bold mb-4'>
            {t(`Add ${workTypeLabel} Work`)}
          </h2>
          <p className='mb-6'>
            {t(
              `Do you want to add a ${workTypeLabel.toLowerCase()} work order?`
            )}
          </p>
          <div className='flex justify-end gap-4'>
            <button
              className='px-4 py-2 border border-gray-500 rounded hover:bg-gray-700 transition'
              onClick={() => handleConfirmResponse(false)}
            >
              {t('No')}
            </button>
            <button
              className='px-4 py-2 bg-gradient rounded font-medium hover:opacity-90 transition'
              onClick={() => handleConfirmResponse(true)}
            >
              {t('Yes')}
            </button>
          </div>
        </div>
      </Modal>

      {/* Work Modal */}
      <Modal
        isOpen={showWorkModal}
        onRequestClose={() => setShowWorkModal(false)}
        style={customStyles}
        contentLabel={`Add ${workTypeLabel} Work`}
        ariaHideApp={false}
      >
        <div className='text-white'>
          <h2 className='text-xl font-bold mb-4'>
            {t(`Add ${workTypeLabel} Work`)}
          </h2>

          {/* Work Orderer Name */}
          <div className='mb-4'>
            <label className='block mb-2 font-medium'>
              {t('WORK_ORDERER_NAME')} <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              name='work_orderer'
              value={workData.work_orderer}
              onChange={handleInputChange}
              className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white'
              placeholder={t('ENTER_WORK_ORDERER_NAME')}
            />
          </div>

          {/* Contact Information Section */}
          <div className='mb-4'>
            {/* Email Input */}
            <div className='mb-4'>
              <label className='block mb-2 font-medium text-sm'>
                {t('WORK_ORDERER_EMAIL')}
              </label>
              <input
                type='email'
                name='work_orderer_email'
                value={workData.work_orderer_email}
                onChange={handleInputChange}
                className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white'
                placeholder={t('ENTER_WORK_ORDERER_EMAIL')}
              />
            </div>

            {/* Phone Input */}
            <div className='mb-4'>
              <label className='block mb-2 font-medium text-sm'>
                {t('WORK_ORDERER_PHONE')}
              </label>
              <input
                type='tel'
                name='work_orderer_phone_no'
                value={workData.work_orderer_phone_no}
                onChange={handleInputChange}
                className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white'
                placeholder={t('ENTER_WORK_ORDERER_PHONE')}
              />
            </div>
          </div>

          {/* Current Mileage */}
          <div className='mb-4'>
            <label className='block mb-2 font-medium'>
              {t('CURRENT_MILEAGE')} <span className='text-red-500'>*</span>
            </label>
            <input
              type='number'
              name='estimated_mileage'
              value={workData.estimated_mileage}
              onChange={handleInputChange}
              className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white'
              placeholder='0'
              min='0'
            />
          </div>

          {/* Additional Fields based on work type */}
          {additionalFields.map((field) => (
            <div className='mb-4' key={field.name}>
              <label className='block mb-2 font-medium'>
                {t(field.label)}{' '}
                {field.required && <span className='text-red-500'>*</span>}
              </label>
              {field.type === 'textarea' ? (
                <textarea
                  name={field.name}
                  value={workData[field.name]}
                  onChange={handleInputChange}
                  className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white h-24'
                  placeholder={t(field.placeholder)}
                />
              ) : (
                <input
                  type={field.type || 'text'}
                  name={field.name}
                  value={workData[field.name]}
                  onChange={handleInputChange}
                  className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white'
                  placeholder={t(field.placeholder)}
                />
              )}
            </div>
          ))}

          {/* Work Order Description (common for all work types) */}
          <div className='mb-6'>
            <label className='block mb-2 font-medium'>
              {t('WORK_ORDER_DESCRIPTION')}
            </label>
            <textarea
              name='work_order_description'
              value={workData.work_order_description}
              onChange={handleInputChange}
              className='w-full px-4 py-2 border border-gray-500 rounded-lg bg-[#252525] text-white h-24'
              placeholder={t('ADDITIONAL_WORK_ORDER_NOTES')}
            />
          </div>

          {/* Action Buttons */}
          <div className='flex justify-end gap-4'>
            <button
              className='px-4 py-2 border border-gray-500 rounded hover:bg-gray-700 transition'
              onClick={() => {
                setShowWorkModal(false);
              }}
            >
              {t('Cancel')}
            </button>
            <button
              className='px-4 py-2 bg-gradient rounded font-medium hover:opacity-90 transition'
              onClick={handleAddWork}
              disabled={isLoading}
            >
              {isLoading ? t('Adding...') : t('Add Work')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenericWorkFlow;
